import { ChainId, JSBI, Token, WETH } from '../sdk'

export const BIPS_BASE = JSBI.BigInt(10000)
export const AddressZero = '0x0000000000000000000000000000000000000000'

export const ROUTER_ADDRESS: any = {
  [ChainId.MAINNET]: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
  [ChainId.SEPOLIA]: '0x44FCf63f47F7d9587b67781cB46842dcFd95A9E8',
  [ChainId.SN_MAIN]: '',
  [ChainId.SN_SEPOLIA]:
    '0x07365f5f8f2f7748d31653133ba5a8be7501d5d90e9893e91372e44b4fe9c967',
  [ChainId.BSC_TESTNET]: '0x77DC5bfe485d1C933B173e2cC3013d24a4B73071',
  [ChainId.MINATO_SONEIUM]: '0x6D1f381cF674CeE888E61b088b78202559eCEEAB',
  [ChainId.VICTION_TESTNET]: '0x44FCf63f47F7d9587b67781cB46842dcFd95A9E8',
  [ChainId.VICTION_MAINNET]: '0x7932606E387479C9cc97efde08BDcaFC5A50ac5A',
  [ChainId.SONIC_TESTNET]: '0x44FCf63f47F7d9587b67781cB46842dcFd95A9E8',
  [ChainId.BASE_SEPOLIA]: '0x7cb2CF3dde3d518b408ea0B83738C067EEdb571D',
  [ChainId.UNICHAIN_SEPOLIA]: '0x7cb2CF3dde3d518b408ea0B83738C067EEdb571D',
  [ChainId.AURORA_TESTNET]: '0x7cb2CF3dde3d518b408ea0B83738C067EEdb571D',
  [ChainId.METIS_MAINNET]: '0xcfdcAC1f0bb8a212990Ec408f0d27A7EC5384ac6',
  [ChainId.TAIKO_TESTNET]: '0x7cb2CF3dde3d518b408ea0B83738C067EEdb571D',
  [ChainId.BOBA_TESTNET]: '0x782783378a9D3BCCC8d9A03F5ED452263758a571',
  [ChainId.NEOX_MAINNET]: '0xa244C6d127Ec19689f0DEf715417736c7f6cf0C4',
  [ChainId.U2U_MAINNET]: '0x40c2Be0C8c421235BDD93714BCac4ffB6A4067a5',
  [ChainId.SCROLL_TESTNET]: '0x782783378a9D3BCCC8d9A03F5ED452263758a571',
  [ChainId.ARBITRUM_MAINNET]: '0x40c2Be0C8c421235BDD93714BCac4ffB6A4067a5',
  [ChainId.OP_MAINNET]: '0x40c2Be0C8c421235BDD93714BCac4ffB6A4067a5',
  [ChainId.BOBA_MAINNET]: '0x40c2Be0C8c421235BDD93714BCac4ffB6A4067a5',
  [ChainId.BERA_MAINNET]: '0x40c2Be0C8c421235BDD93714BCac4ffB6A4067a5'
}

export const ROUTER_ADDRESS_WITH_PRICE: any = {
  [ChainId.VICTION_MAINNET]: '0x0194CcfC49C3ebc7457E0b41B9c6b840C22f5985',
  [ChainId.SONIC_TESTNET]: '0x0f97Ca4E6B118502f83DD3Ce836A14Cb4937ed2a',
  [ChainId.AURORA_TESTNET]: '0x83A8D57634239a9e52197d34cbc74CD9455383d1',
  [ChainId.TAIKO_TESTNET]: '0x68Ce9bf4De2E0f44f39d80611d21556665120b91',
  [ChainId.BOBA_TESTNET]: '0x44FCf63f47F7d9587b67781cB46842dcFd95A9E8',
  [ChainId.BOBA_MAINNET]: '0xa244C6d127Ec19689f0DEf715417736c7f6cf0C4',
  [ChainId.BERA_MAINNET]: '0xa244C6d127Ec19689f0DEf715417736c7f6cf0C4'
}

export const PYTH_ADDRESS: any = {
  [ChainId.VICTION_MAINNET]: '0xA2aa501b19aff244D90cc15a4Cf739D2725B5729',
  [ChainId.SONIC_TESTNET]: '0x96124d1f6e44ffdf1fb5d6d74bb2de1b7fbe7376',
  [ChainId.AURORA_TESTNET]: '0x74f09cb3c7e2A01865f424FD14F6dc9A14E3e94E',
  [ChainId.TAIKO_TESTNET]: '0x2880aB155794e7179c9eE2e38200202908C17B43',
  [ChainId.BOBA_TESTNET]: '0x8D254a21b3C86D32F7179855531CE99164721933',
  [ChainId.BOBA_MAINNET]: '0x4374e5a8b9C22271E9EB878A2AA31DE97DF15DAF',
  [ChainId.BERA_MAINNET]: '0x2880aB155794e7179c9eE2e38200202908C17B43'
}

export const RPC_URLS: any = {
  [ChainId.SEPOLIA]:
    'https://sepolia.infura.io/v3/5f8bfb8bae3945f28da51d768f736046',
  [ChainId.BSC_TESTNET]: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  [ChainId.VICTION_TESTNET]: 'https://rpc-testnet.viction.xyz',
  [ChainId.VICTION_MAINNET]: 'https://rpc.viction.xyz',
  [ChainId.SONIC_TESTNET]: 'https://rpc.testnet.soniclabs.com',
  [ChainId.MINATO_SONEIUM]: 'https://rpc.minato.soneium.org/',
  [ChainId.BASE_SEPOLIA]: 'https://base-sepolia-rpc.publicnode.com',
  [ChainId.UNICHAIN_SEPOLIA]: 'https://sepolia.unichain.org',
  [ChainId.AURORA_TESTNET]: 'https://testnet.aurora.dev',
  [ChainId.METIS_MAINNET]: 'https://andromeda.metis.io/?owner=1088',
  [ChainId.TAIKO_TESTNET]: 'https://rpc.hekla.taiko.xyz',
  [ChainId.BOBA_TESTNET]: 'https://sepolia.boba.network',
  [ChainId.NEOX_MAINNET]: 'https://mainnet-1.rpc.banelabs.org',
  [ChainId.U2U_MAINNET]: 'https://rpc-mainnet.u2u.xyz',
  [ChainId.SCROLL_TESTNET]: 'https://scroll-sepolia.chainstacklabs.com',
  [ChainId.ARBITRUM_MAINNET]: 'https://arbitrum.llamarpc.com',
  [ChainId.OP_MAINNET]: 'https://optimism.llamarpc.com',
  [ChainId.BOBA_MAINNET]: 'https://mainnet.boba.network',
  [ChainId.BERA_MAINNET]: 'https://berachain.blockpi.network/v1/rpc/public'
}

export enum Field {
  CURRENCY_A = 'CURRENCY_A',
  CURRENCY_B = 'CURRENCY_B',
  LIQUIDITY_PERCENT = 'LIQUIDITY_PERCENT',
  LIQUIDITY = 'LIQUIDITY'
}

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED
}

export const DAI = new Token(
  ChainId.MAINNET,
  '0x6B175474E89094C44Da98b954EedeAC495271d0F',
  18,
  'DAI',
  'Dai Stablecoin'
)
export const USDC = new Token(
  ChainId.MAINNET,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD//C'
)
export const USDT = new Token(
  ChainId.MAINNET,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD'
)
export const WBTC = new Token(
  ChainId.MAINNET,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  8,
  'WBTC',
  'Wrapped BTC'
)

export const BASE_USDC = new Token(
  ChainId.BASE_SEPOLIA,
  '0x036CbD53842c5426634e7929541eC2318f3dCF7e',
  6,
  'USDC',
  'USDC'
)

export const UNICHAIN_USDC = new Token(
  ChainId.UNICHAIN_SEPOLIA,
  '0x8Aca9B80b6752Ec62e06eC48E07a301e97852dAA',
  18,
  'USDC',
  'USDC'
)

export const METIS_USDC = new Token(
  ChainId.METIS_MAINNET,
  '0xEA32A96608495e54156Ae48931A7c20f0dcc1a21',
  6,
  'USDC',
  'USDC'
)

export const PINNED_PAIRS: {
  readonly [chainId in ChainId]?: [Token, Token][]
} = {
  [ChainId.MAINNET]: [
    [
      new Token(
        ChainId.MAINNET,
        '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643',
        8,
        'cDAI',
        'Compound Dai'
      ),
      new Token(
        ChainId.MAINNET,
        '0x39AA39c021dfbaE8faC545936693aC917d5E7563',
        8,
        'cUSDC',
        'Compound USD Coin'
      )
    ],
    [USDC, USDT],
    [DAI, USDT]
  ]
}

type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.SEPOLIA]: [WETH[ChainId.SEPOLIA]],
  [ChainId.SN_MAIN]: [WETH[ChainId.SN_MAIN]],
  [ChainId.SN_SEPOLIA]: [WETH[ChainId.SN_SEPOLIA]],
  [ChainId.BSC_TESTNET]: [WETH[ChainId.BSC_TESTNET]],
  [ChainId.VICTION_TESTNET]: [WETH[ChainId.VICTION_TESTNET]],
  [ChainId.VICTION_MAINNET]: [WETH[ChainId.VICTION_MAINNET]],
  [ChainId.SONIC_TESTNET]: [WETH[ChainId.SONIC_TESTNET]],
  [ChainId.MINATO_SONEIUM]: [WETH[ChainId.MINATO_SONEIUM]],
  [ChainId.BASE_SEPOLIA]: [WETH[ChainId.BASE_SEPOLIA]],
  [ChainId.UNICHAIN_SEPOLIA]: [WETH[ChainId.UNICHAIN_SEPOLIA]],
  [ChainId.AURORA_TESTNET]: [WETH[ChainId.AURORA_TESTNET]],
  [ChainId.METIS_MAINNET]: [WETH[ChainId.METIS_MAINNET]],
  [ChainId.TAIKO_TESTNET]: [WETH[ChainId.TAIKO_TESTNET]],
  [ChainId.BOBA_TESTNET]: [WETH[ChainId.BOBA_TESTNET]],
  [ChainId.NEOX_MAINNET]: [WETH[ChainId.NEOX_MAINNET]],
  [ChainId.U2U_MAINNET]: [WETH[ChainId.U2U_MAINNET]],
  [ChainId.SCROLL_TESTNET]: [WETH[ChainId.SCROLL_TESTNET]],
  [ChainId.ARBITRUM_MAINNET]: [WETH[ChainId.ARBITRUM_MAINNET]],
  [ChainId.OP_MAINNET]: [WETH[ChainId.OP_MAINNET]],
  [ChainId.BOBA_MAINNET]: [WETH[ChainId.BOBA_MAINNET]],
  [ChainId.BERA_MAINNET]: [WETH[ChainId.BERA_MAINNET]]
}

export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, WBTC]
}

export interface SerializedToken {
  chainId: number
  address: string
  decimals: number
  symbol?: string
  name?: string
}

export interface SerializedPair {
  token0: SerializedToken
  token1: SerializedToken
}
