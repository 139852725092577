import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import {
  ChainId,
  JSBI,
  Percent,
  Router,
  SwapParameters,
  Trade,
  TradeType
} from '../sdk'
import isZero, {
  calculateGasMargin,
  getRouterContract,
  getRouterContractWithPrice
} from '../utils/utils'
import { TransactionResponse, Web3Provider } from '@ethersproject/providers'
import { BIPS_BASE, ROUTER_ADDRESS } from '../constants/constants'
import { AccountInterface, Contract as StarknetContract } from 'starknet'
import ISNPair from '../abis/ISNPair.json'
import ISNRouter from '../abis/ISNRouter.json'

export enum SwapCallbackState {
  INVALID,
  LOADING,
  VALID
}

export interface SwapCall {
  contract: Contract
  parameters: SwapParameters
}

export interface SuccessfulCall {
  call: SwapCall
  gasEstimate: BigNumber
}

export interface FailedCall {
  call: SwapCall
  error: Error
}

export type EstimatedSwapCall = SuccessfulCall | FailedCall

function getSwapCallArguments(
  trade: Trade,
  account: string,
  allowedSlippage: number,
  recipient: string,
  chainId: number,
  library: Web3Provider,
  deadline: BigNumber
): { parameters: SwapParameters; contract: Contract }[] {
  const contract: Contract | null =
    chainId === ChainId.VICTION_MAINNET ||
    chainId === ChainId.SONIC_TESTNET ||
    chainId === ChainId.AURORA_TESTNET ||
    chainId === ChainId.TAIKO_TESTNET ||
    chainId === ChainId.BOBA_TESTNET ||
    chainId === ChainId.BOBA_MAINNET ||
    chainId === ChainId.BERA_MAINNET
      ? getRouterContractWithPrice(chainId, library, account)
      : getRouterContract(chainId, library, account)
  if (!contract) {
    return []
  }

  const swapMethods = []

  swapMethods.push(
    Router.swapCallParameters(
      trade,
      {
        feeOnTransfer: false,
        allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
        recipient,
        deadline: deadline.toNumber()
      },
      chainId
    )
  )

  if (trade.tradeType === TradeType.EXACT_INPUT) {
    swapMethods.push(
      Router.swapCallParameters(
        trade,
        {
          feeOnTransfer: true,
          allowedSlippage: new Percent(JSBI.BigInt(allowedSlippage), BIPS_BASE),
          recipient,
          deadline: deadline.toNumber()
        },
        chainId
      )
    )
  }
  return swapMethods.map((parameters) => ({ parameters, contract }))
}

export const callSwapContractStarknet = async (
  chainId: ChainId,
  library: AccountInterface,
  account: string, // the ENS name or address of the recipient of the trade, or null if swap should be returned to sender,
  trade: Trade, // trade to execute, required
  allowedSlippage: number,
  deadline: BigNumber
): Promise<TransactionResponse> => {
  try {
    if (!account || !allowedSlippage || !trade.inputAmount) {
      throw new Error(
        'Unexpected error. Please contact support: none of the calls threw an error'
      )
    }
    const approveCall = new StarknetContract(
      ISNPair,
      trade.route.path[0].address,
      library
    ).populate('approve', {
      spender: ROUTER_ADDRESS[chainId],
      amount: trade.inputAmount.raw.toString()
    })

    // swap tokens for tokens
    const swapCall = new StarknetContract(
      ISNRouter,
      ROUTER_ADDRESS[chainId],
      library
    ).populate('swap_exact_tokens_for_tokens', {
      amountIn: trade.inputAmount.raw.toString(),
      amountOutMin: '0',
      path: [
        {
          tokenIn: trade.route.path[0].address,
          tokenOut: trade.route.path[1].address
        }
      ],
      to: library.address,
      deadline
    })

    const tx = await library.execute([approveCall, swapCall])
    // await library.waitForTransaction(tx.transaction_hash)
    return { hash: tx.transaction_hash } as TransactionResponse
  } catch (error) {
    console.error('user reject transaction', error)
    throw error
  }
}

export async function callSwapContract(
  trade: Trade,
  account: string,
  allowedSlippage: number,
  recipient: string,
  chainId: number,
  library: Web3Provider,
  deadline: BigNumber
): Promise<TransactionResponse> {
  if (chainId === ChainId.SN_SEPOLIA || chainId === ChainId.SN_MAIN) {
    return callSwapContractStarknet(
      chainId,
      library as any,
      account,
      trade,
      allowedSlippage,
      deadline
    )
  }
  const swapCalls = getSwapCallArguments(
    trade,
    account,
    allowedSlippage,
    recipient,
    chainId,
    library,
    deadline
  )

  const estimatedCalls: EstimatedSwapCall[] = await Promise.all(
    swapCalls.map((call) => {
      const {
        parameters: { methodName, args, value },
        contract
      } = call
      const options = !value || isZero(value) ? {} : { value }

      return contract.estimateGas[methodName](...args, options)
        .then((gasEstimate) => {
          return {
            call,
            gasEstimate
          }
        })
        .catch((gasError) => {
          console.debug(
            'Gas estimate failed, trying eth_call to extract error',
            call
          )

          return contract.callStatic[methodName](...args, options)
            .then((result) => {
              console.debug(
                'Unexpected successful call after failed estimate gas',
                call,
                gasError,
                result
              )
              return {
                call,
                error: new Error(
                  'Unexpected issue with estimating the gas. Please try again.'
                )
              }
            })
            .catch((callError) => {
              console.debug('Call threw error', call, callError)
              let errorMessage: string
              switch (callError.reason) {
                case 'UniswapV2Router: INSUFFICIENT_OUTPUT_AMOUNT':
                case 'UniswapV2Router: EXCESSIVE_INPUT_AMOUNT':
                  errorMessage =
                    'This transaction will not succeed either due to price movement or fee on transfer. Try increasing your slippage tolerance.'
                  break
                default:
                  errorMessage = `The transaction cannot succeed due to error: ${callError.reason}. This is probably an issue with one of the tokens you are swapping.`
              }
              return { call, error: new Error(errorMessage) }
            })
        })
    })
  )

  // a successful estimation is a bignumber gas estimate and the next call is also a bignumber gas estimate
  const successfulEstimation: any = estimatedCalls.find(
    (el: any) => el?.gasEstimate
  )

  if (!successfulEstimation) {
    const errorCalls = estimatedCalls.filter(
      (call): call is FailedCall => 'error' in call
    )
    if (errorCalls.length > 0) throw errorCalls[errorCalls.length - 1].error
    throw new Error(
      'Unexpected error. Please contact support: none of the calls threw an error'
    )
  }

  const {
    call: {
      contract,
      parameters: { methodName, args, value }
    },
    gasEstimate
  } = successfulEstimation

  return contract[methodName](...args, {
    gasLimit: calculateGasMargin(gasEstimate),
    ...(value && !isZero(value) ? { value, from: account } : { from: account })
  })
    .then((response: any) => {
      return response
    })
    .catch((error: any) => {
      // if the user rejected the tx, pass this along
      if (error?.code === 4001) {
        throw new Error('Transaction rejected.')
      } else {
        // otherwise, the error was unexpected and we need to convey that
        console.error(`Swap failed`, error, methodName, args, value)
        throw new Error(`Swap failed: ${error.message}`)
      }
    })
}
