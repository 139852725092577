import invariant from 'tiny-invariant'
import { ChainId } from '../constants'
import { validateAndParseAddress } from '../utils'
import { Currency } from './currency'

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class Token extends Currency {
  public readonly chainId: ChainId
  public readonly address: string

  public constructor(
    chainId: ChainId,
    address: string,
    decimals: number,
    symbol?: string,
    name?: string
  ) {
    super(decimals, symbol, name)
    this.chainId = chainId
    this.address = validateAndParseAddress(address, chainId)
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Token): boolean {
    // short circuit on reference equality
    if (this === other) {
      return true
    }
    return this.chainId === other.chainId && this.address === other.address
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: Token): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS')
    invariant(this.address !== other.address, 'ADDRESSES')
    return this.address.toLowerCase() < other.address.toLowerCase()
  }
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(
  currencyA: Currency | undefined,
  currencyB: Currency | undefined
): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return currencyA.equals(currencyB)
  } else if (currencyA instanceof Token) {
    return false
  } else if (currencyB instanceof Token) {
    return false
  } else {
    return currencyA === currencyB
  }
}

export const WETH = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xC054751BdBD24Ae713BA3Dc9Bd9434aBe2abc1ce',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.SEPOLIA]: new Token(
    ChainId.SEPOLIA,
    '0x8Aca9B80b6752Ec62e06eC48E07a301e97852dAA',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.SN_MAIN]: new Token(
    ChainId.SN_SEPOLIA as any,
    '0x49d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7',
    18,
    'ETH',
    'Ether'
  ),
  [ChainId.SN_SEPOLIA]: new Token(
    ChainId.SN_SEPOLIA as any,
    '0x49d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7',
    18,
    'ETH',
    'Ether'
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET as any,
    '0xCF1F5dB1a874Ebf820019c4CCA11c32ebb5eE33A',
    18,
    'WBNB',
    'Wrapped BNB'
  ),
  [ChainId.VICTION_TESTNET]: new Token(
    ChainId.VICTION_TESTNET as any,
    '0x8Aca9B80b6752Ec62e06eC48E07a301e97852dAA',
    18,
    'WVIC',
    'Wrapped VIC'
  ),
  [ChainId.VICTION_MAINNET]: new Token(
    ChainId.VICTION_MAINNET as any,
    '0xC054751BdBD24Ae713BA3Dc9Bd9434aBe2abc1ce',
    18,
    'WVIC',
    'Wrapped VIC'
  ),
  [ChainId.SONIC_TESTNET]: new Token(
    ChainId.SONIC_TESTNET as any,
    '0x782783378a9D3BCCC8d9A03F5ED452263758a571',
    18,
    'WS',
    'Wrapped S'
  ),
  [ChainId.MINATO_SONEIUM]: new Token(
    ChainId.MINATO_SONEIUM as any,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped ETH'
  ),
  [ChainId.BASE_SEPOLIA]: new Token(
    ChainId.BASE_SEPOLIA as any,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped Ether'
  ),
  [ChainId.UNICHAIN_SEPOLIA]: new Token(
    ChainId.UNICHAIN_SEPOLIA as any,
    '0x4200000000000000000000000000000000000006',
    18,
    'ETH',
    'Wrapped Ether'
  ),
  [ChainId.AURORA_TESTNET]: new Token(
    ChainId.AURORA_TESTNET as any,
    '0x8aca9b80b6752ec62e06ec48e07a301e97852daa',
    18,
    'WETH',
    'Wrapped ETH'
  ),
  [ChainId.METIS_MAINNET]: new Token(
    ChainId.METIS_MAINNET as any,
    '0x75cb093e4d61d2a2e65d8e0bbb01de8d89b53481',
    18,
    'WMETIS',
    'Wrapped METIS'
  ),
  [ChainId.TAIKO_TESTNET]: new Token(
    ChainId.TAIKO_TESTNET as any,
    '0xae2C46ddb314B9Ba743C6dEE4878F151881333D9',
    18,
    'WETH',
    'Wrapped ETH'
  ),
  [ChainId.BOBA_TESTNET]: new Token(
    ChainId.BOBA_TESTNET as any,
    '0x0f97Ca4E6B118502f83DD3Ce836A14Cb4937ed2a',
    18,
    'WBOBA',
    'Wrapped BOBA'
  ),
  [ChainId.NEOX_MAINNET]: new Token(
    ChainId.NEOX_MAINNET as any,
    '0xdE41591ED1f8ED1484aC2CD8ca0876428de60EfF',
    18,
    'WGAS10',
    'Wrapped GAS v10'
  ),
  [ChainId.U2U_MAINNET]: new Token(
    ChainId.U2U_MAINNET as any,
    '0xA99cf32e9aAa700f9E881BA9BF2C57A211ae94df',
    18,
    'WU2U',
    'Wrapped U2U'
  ),
  [ChainId.SCROLL_TESTNET]: new Token(
    ChainId.SCROLL_TESTNET as any,
    '0x86d86dD68a2D7FD82de9760D447f1Ef11644B535',
    18,
    'WETH',
    'Wrapped ETH'
  ),
  [ChainId.ARBITRUM_MAINNET]: new Token(
    ChainId.ARBITRUM_MAINNET as any,
    '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
    18,
    'WETH',
    'Wrapped ETH'
  ),
  [ChainId.OP_MAINNET]: new Token(
    ChainId.OP_MAINNET as any,
    '0x4200000000000000000000000000000000000006',
    18,
    'WETH',
    'Wrapped ETH'
  ),
  [ChainId.BOBA_MAINNET]: new Token(
    ChainId.BOBA_MAINNET as any,
    '0xa18bF3994C0Cc6E3b63ac420308E5383f53120D7',
    18,
    'ETH',
    'Wrapped ETH'
  ),
  [ChainId.BERA_MAINNET]: new Token(
    ChainId.BERA_MAINNET as any,
    '0x6969696969696969696969696969696969696969',
    18,
    'WBERA',
    'Wrapped Bera'
  )
}
